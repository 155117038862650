import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { CustomDialogComponent } from '../../../../superadmin/custom-dialog/custom-dialog.component';

@Component({
  selector: 'select-list-dialog',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListDialogComponent implements OnInit {

    wordFilter: string = '';
    
	constructor(
		private router: Router,
		private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
		private dialog: MatDialog,
		public dialogRef: MatDialogRef<SelectListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

    choose(item){
        switch (this.data.type) {
            case 'version':
                this.data.device.OS.version = item;
            break;

            case 'platform':
                this.data.device.OS.platform = item.name;
            break;
        
            case 'oss':
                this.data.device.OS.platform = item;
            break;

            case 'farm':
                this.data.device.deviceFarms = [];
                this.data.device.deviceFarms.push(item);
            break;

            case 'teams':
                this.data.account.team = item;
            break;

            case 'deviceHub':
                this.data.device.deviceHub = item;
            break;

            case 'manufacturer':
                this.data.model.manufacturer = item;
            break;

            case 'deviceModel':
                this.data.device.model = item;
            break;

            case 'devices':
                this.data.device = item;
                return this.dialogRef.close(item);
            break
            
            case 'action':
                this.data.action = item.name;
                return this.dialogRef.close(item);
            break;

            case 'methods':
                this.data.company.plan.method = item.method;
            break;

            default:
            break;
        }
        return this.dialogRef.close();
    }

	ngOnInit() {
        console.log(this.data)

        if(this.data.type == 'teams'){
            console.log(this.data.list)
        }
	}
}
