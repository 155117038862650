import { LoginService } from './login/login.service';
import { DevicesService } from './devices/devices.service';
import * as providers from './providers';

export const setup = (loginService: LoginService, deviceService: DevicesService) => {

	let check: () => Promise<any>;
	
	if (LoginService.authToken() === '') {
		check = () => Promise.resolve();
	} else {
		check = () => new Promise((resolve, reject) => {
			loginService.checkAuth().subscribe(
				(result: any) => {
					loginService.auth(true);
					loginService.user = result.json();

					/*
					// exemplo de widgets sem consumir o banco de dados
					loginService.user.team.widgets =  [
						{
							title: "storageConsumption",
							enabled: true,
							position: {
								x: 2,
								y: 1,
							},
							size: 24,
						},
						{
							title: "apiKey",
							enabled: false,
							position: {
								x: 2,
								y: 1,
							},
							size: 24,
						},
						{
							title: "lcai",
							enabled: true,
							position: {
								x: 2,
								y: 1,
							},
							size: 24,
						}
					]
					*/

					resolve();
				},
				(error: any) => {
					loginService.auth(false);
					resolve();
				},
			);
		});
	}

	const pressetsTask = () => new Promise((resolve, reject) => {
		deviceService.getPressets().subscribe(
			(result: any) => {
				const pressets = result.json();
				deviceService.types = pressets.types;
				deviceService.manufacturers = pressets.manufacturers;
				deviceService.models = pressets.models;
				deviceService.oss = pressets.oss;
				deviceService.osVersions = pressets.osVersions;

				deviceService.models = deviceService.models.map((model: any) => {
					model.manufacturer = pressets.manufacturers.find( (manufacturer: any) => {
						if (manufacturer._id === model.manufacturer) {
							return manufacturer;
						}
						return false;
					});

					model.type = model.type.map((type: any) => {
						type = pressets.types.find( (ftype: any) => {
							if (ftype._id === type) {
								return type;
							}
							return false;
						});
						return type;
					});
					return model;
				});

				// deviceService.osVersions = deviceService.osVersions.map((osv) => {
				// 	osv.deviceOS = deviceService.oss.find( (oss) => {
				// 		if (oss._id === osv.deviceOS) {
				// 			return oss;
				// 		}
				// 		return false;
				// 	});
				// 	return osv;
				// });
				resolve();
			},
			(error: any) => {
				reject(error);
			},
		);
	});

	const devices = () => new Promise((resolve, reject) => {
		deviceService.getDevices().subscribe(
			async (result: any) => {
				// console.log('bootstrapFactory', result.json())
				deviceService.devices = await result.json();
				
				deviceService.setAvaiablePressets();
				resolve(deviceService.devices);
			},
			(error: any) => {
				reject(error);
			},
		);
	});

	return () => check().then(function(){
		return new Promise((resolve, reject) => {
			if (!loginService.isLoggedIn) {
				return resolve();
			}
			return pressetsTask()
			//.then(devices)
			.then(function(){
				resolve();
			});
		});
	});
};
